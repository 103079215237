import { Layout } from 'antd'
import { Outlet, useOutletContext } from 'react-router-dom'

const SettingsLayout = () => {
  return (
    <Layout className="h-full flex-col flex">
      <Layout.Content className="px-4 py-4 md:px-8 flex flex-col h-full lg:pt-10 md:pb-6 bg-white md:overflow-auto">
        <Outlet context={useOutletContext()} />
      </Layout.Content>
    </Layout>
  )
}

export default SettingsLayout

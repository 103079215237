import { FC } from 'react'
import { Menu, Avatar, Skeleton } from 'antd'
import { Link } from 'react-router-dom'
import { useWorkspace } from 'hooks/useWorkspace'
import { AppRoutes } from 'app/app-routes'
import clsx from 'clsx'
import Flex from 'antd/es/flex'

type Props = {
  collapsed?: boolean
}

const WorkspaceMenu: FC<Props> = ({ collapsed }) => {
  const { currentWorkspace, workspaces, isLoading } = useWorkspace()

  return (
    <Menu
      theme="light"
      style={{ border: 'none' }}
      defaultSelectedKeys={[location.pathname]}
      items={[
        {
          key: 'workspace',
          label: isLoading ? (
            <Flex gap={8} align="center">
              <Skeleton.Avatar active size={40} shape="circle" />
              {!collapsed && (
                <Skeleton.Button active size="small" shape="default" block className="max-w-36 my-auto flex" />
              )}
            </Flex>
          ) : (
            <Flex gap={8} align="center">
              <Avatar size={40} src={currentWorkspace?.logoUrl} />
              {!collapsed && (
                <div className="font-semibold overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px]">
                  {currentWorkspace?.name}
                </div>
              )}
            </Flex>
          ),
          popupClassName:
            'min-w-64 [&_.ant-menu-item]:!h-[40px] [&_.ant-menu-item]:!flex [&_.ant-menu-item]:items-center',
          className: clsx(
            'max-xl:hidden [&_.ant-menu-submenu-title]:!flex [&_.ant-menu-submenu-title]:!items-center [&_.ant-menu-title-content]:!overflow-visible !mb-6',
            [
              collapsed
                ? '[&_.ant-menu-submenu-title]:!px-0 [&_.ant-menu-submenu-title]:!h-[40px] [&_.ant-menu-submenu-title]:!rounded-full'
                : '[&_.ant-menu-submenu-title]:!px-3 [&_.ant-menu-submenu-title]:!h-[50px]'
            ]
          ),
          ...(workspaces?.length && {
            children: workspaces?.map((item) => ({
              key: item?.id,
              label: (
                <Link to={`${AppRoutes.workspaces}/${item.id}/space`}>
                  <Flex gap={10} align="center">
                    <Avatar size={30} src={item?.logoUrl} />
                    <div className="font-semibold text-base">{item?.name}</div>
                  </Flex>
                </Link>
              )
            }))
          })
        }
      ]}
    />
  )
}

export default WorkspaceMenu

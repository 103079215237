import { Middleware, isRejectedWithValue, SerializedError } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

interface RejectedActionPayload {
  status?: number
  data?: {
    message?: string | string[]
    error?: string | string[]
  }
}

interface ExtendedError extends SerializedError {
  data?: {
    message?: string
  }
}

/**
 * Middleware to log and display errors from rejected actions
 */
export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    // Handle specific status codes
    const { status, data } = action.payload as RejectedActionPayload
    if (status) {
      handleStatusBasedErrors(status, data)
    } else {
      const errorMessage = (action.error as ExtendedError)?.data?.message || ''
      if (errorMessage) toast.error(errorMessage)
    }
  }

  return next(action)
}

/**
 * Handles errors based on HTTP status codes and displays relevant messages.
 */
function handleStatusBasedErrors(status: number, data: any): void {
  switch (status) {
    case 400:
      handleBadRequest(data)
      break
    case 409:
      handleBadRequest(data, true)
      break
    case 500:
      toast.error(data.message)
      break
    default:
      if (data?.message) {
        toast.error(data.message)
      } else {
        toast.error('An error occurred')
      }
      break
  }
}

/**
 * Specifically handles 400 Bad Request responses.
 */
function handleBadRequest(data: any, isCsvError: boolean = false): void {
  if (isCsvError) {
    const error = data.error
    toast.error(error)

    const blob = new Blob([data.data])
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', data.errorFileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return
  }

  const { error, message } = data
  if (message) {
    if (typeof message === 'string') {
      toast.error(message)
    } else {
      message.forEach((msg: string) => toast.error(msg))
    }
  } else if (error) {
    if (typeof error === 'string') {
      toast.error(error)
    } else {
      error.forEach((err: string) => toast.error(err))
    }
  }
}

import { Pto } from '@outcoreaix/pto'
import { Flex, Skeleton } from 'antd'
import { authStorage } from 'app/auth-storage'
import { useWorkspace } from 'hooks/useWorkspace'
import { FC, useState } from 'react'
import { WorkspaceMenu } from '../WorkspaceMenu'
import { UserMenu } from '../UserMenu'
import { DrawerMenu } from '../DrawerMenu'
import { useGetSpacesQuery } from 'api/spaces-api'
import { generateSpaceSettingsMenu, generateWorkspaceSettingsMenu } from '../menu-items'

type Props = {
  onClick?: () => void
  isDesktop?: boolean
  collapsed?: boolean
}

const MenuList: FC<Props> = ({ onClick, isDesktop, collapsed }) => {
  const { currentWorkspace, currentWorkspaceId, workspaces, isLoading } = useWorkspace()
  const { data: spacesData, isLoading: isSpacesLoading } = useGetSpacesQuery(
    {
      workspaceId: currentWorkspaceId!
    },
    { skip: currentWorkspaceId === undefined }
  )
  const spaces = spacesData?.items.filter((item) => item.workspaceId === Number(currentWorkspace?.id))
  const user = authStorage.user
  const [spacesSubMenu, setSpacesSubMenu] = useState('')
  const [openWorkspaceSubMenu, setOpenWorkspaceSubMenu] = useState(false)

  const handleClose = () => {
    setSpacesSubMenu('')
    setOpenWorkspaceSubMenu(false)
    onClick?.()
  }

  const handleSubMenuClick = (value: string, type?: 'workspace' | 'space') => {
    if (!value) {
      handleClose()
      return
    }

    if (type === 'space') {
      setSpacesSubMenu(value)
    } else {
      setOpenWorkspaceSubMenu(!!value)
    }
  }

  if (isLoading || isSpacesLoading) {
    return (
      <Flex vertical gap={8}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Skeleton.Button
            key={`menu_loading_${index}`}
            active
            className="[&_.ant-skeleton-button]:!w-full [&_.ant-skeleton-button]:!min-w-12 [&_.ant-skeleton-button]:!h-10"
          />
        ))}
      </Flex>
    )
  }
  return (
    <Flex vertical>
      {!!workspaces?.length && !user?.roles.includes(Pto.Users.UserRole.SystemAdmin) && (
        <WorkspaceMenu collapsed={collapsed} />
      )}
      <UserMenu onClick={onClick} isDesktop={isDesktop} collapsed={collapsed} openSubMenu={handleSubMenuClick} />
      <DrawerMenu
        title={
          (!!spacesSubMenu
            ? spaces?.find((item) => spacesSubMenu === item.id.toString())?.name
            : currentWorkspace?.name) || ''
        }
        items={
          !!spacesSubMenu
            ? generateSpaceSettingsMenu(currentWorkspace?.id, Number(spacesSubMenu), handleClose)
            : generateWorkspaceSettingsMenu(currentWorkspace?.id, handleClose)
        }
        open={!!spacesSubMenu || openWorkspaceSubMenu}
        onClose={handleClose}
      />
    </Flex>
  )
}

export default MenuList

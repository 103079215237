import { ComponentProps, FC } from 'react'
import { Drawer, Menu } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

type Props = {
  title: string
  items: ComponentProps<typeof Menu>['items']
  open: boolean
  onClose: () => void
}

const DrawerMenu: FC<Props> = ({ title, items, open, onClose }) => {
  return (
    <Drawer
      title={<div className="overflow-hidden text-ellipsis whitespace-nowrap max-w-[165px]">{title}</div>}
      placement="left"
      onClose={onClose}
      open={open}
      closeIcon={<LeftOutlined />}
    >
      <Menu theme="light" mode="inline" className="h-full" style={{ border: 'none' }} items={items} />
    </Drawer>
  )
}

export default DrawerMenu

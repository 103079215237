import { FC } from 'react'
import { Menu } from 'antd'
import { useLocation } from 'react-router-dom'
import { useWorkspace } from 'hooks/useWorkspace'
import { useGetSpacesQuery } from 'api/spaces-api'
import { authStorage } from 'app/auth-storage'
import { generateUserMenuItems, generateSystemAdminMenuItems } from '../menu-items'
import { Pto } from '@outcoreaix/pto'

type Props = {
  onClick?: () => void
  isDesktop?: boolean
  collapsed?: boolean
  openSubMenu: (values: string, type?: 'workspace' | 'space') => void
}

const UserMenu: FC<Props> = ({ onClick, isDesktop, openSubMenu, collapsed = false }) => {
  const location = useLocation()
  const { currentWorkspace, currentWorkspaceId } = useWorkspace()
  const { data: spacesData } = useGetSpacesQuery(
    {
      workspaceId: currentWorkspaceId!
    },
    { skip: currentWorkspaceId === undefined }
  )
  const user = authStorage.user
  const spaces = spacesData?.items.filter((item) => item.workspaceId === Number(currentWorkspace?.id))

  const onSubItemClick = (value: string, type: 'workspace' | 'space') => {
    openSubMenu(value, type)
  }

  const handleClick = () => {
    openSubMenu('')
    onClick?.()
  }

  return (
    <Menu
      mode="inline"
      theme="light"
      style={{ border: 'none' }}
      defaultSelectedKeys={[location.pathname]}
      {...(!collapsed && {
        defaultOpenKeys: [`spaces_chat`]
      })}
      items={
        user?.roles.includes(Pto.Users.UserRole.SystemAdmin)
          ? generateSystemAdminMenuItems(handleClick)
          : generateUserMenuItems(currentWorkspace, handleClick, spaces, onSubItemClick, isDesktop)
      }
    />
  )
}

export default UserMenu

import { Form, Typography, Upload } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload'
import { FC } from 'react'
import { extractFileNameFromURL } from 'utils/extract-file-name-from-url'

export type Values = {
  kb_file: UploadFile<RcFile>
}

type Props = {
  kbUrl?: string
  name?: string
  onSubmit: (data: Values) => Promise<void>
  disabled?: boolean
}

const KnowledgeBaseForm: FC<Props> = ({ kbUrl = '', name = 'workspace_knowledgebase', onSubmit, disabled = false }) => {
  const [form] = Form.useForm()

  return (
    <Form
      initialValues={{ kb_file: kbUrl }}
      layout="vertical"
      onFinish={onSubmit}
      form={form}
      name={name}
      disabled={disabled}
    >
      <Form.Item
        name="kb_file"
        label="Please upload a Knowledge Base document"
        rules={[{ required: true, message: 'Please upload a Knowledge Base document' }]}
      >
        <Form.Item name="kb_file" valuePropName="file" getValueFromEvent={(event) => event?.file} noStyle>
          <Upload.Dragger
            name="file"
            {...(kbUrl && {
              defaultFileList: [
                {
                  uid: '1',
                  name: extractFileNameFromURL(kbUrl),
                  url: kbUrl
                }
              ]
            })}
            customRequest={async (info) => {
              await form.validateFields()
              info.onSuccess?.('Ok')
            }}
            beforeUpload={(file) => {
              return new Promise((resolve, reject) => {
                if (file.size > 1024 * 1024 * 5) {
                  reject('File size exceeded')
                } else {
                  resolve('Success')
                }
              })
            }}
            maxCount={1}
            accept=".csv,.doc,.docx,.pdf,.txt"
            style={{ padding: '25px 60px' }}
          >
            <Typography.Text>
              <strong>Click to upload</strong> or drag and drop DOC, DOCX, CSV, TXT, PDF
            </Typography.Text>
          </Upload.Dragger>
        </Form.Item>
      </Form.Item>
    </Form>
  )
}

export default KnowledgeBaseForm

import { Pto } from '@outcoreaix/pto'

const stage = process.env.NODE_ENV
const environment = process.env.REACT_APP_ENVIRONMENT || 'development'

let pluginStyleUrl = ''
let pluginScriptUrl = ''

switch (environment) {
  case 'development':
    pluginStyleUrl = `http://localhost:3000/plugin/v1/style.css`
    pluginScriptUrl = `http://localhost:3000/plugin/v1/chat-plugin.umd-development.js`
    break
  case 'production':
    pluginStyleUrl = 'https://app.outcore.ai/plugin/v1/style.css'
    pluginScriptUrl = 'https://app.outcore.ai/plugin/v1/chat-plugin.umd.js'
    break
  default:
    pluginStyleUrl = 'https://app-staging.outcore.ai/plugin/v1/style.css'
    pluginScriptUrl = 'https://app-staging.outcore.ai/plugin/v1/chat-plugin.umd-staging.js'
}

export const settings = {
  backend: {
    host: process.env.REACT_APP_BACKEND_HOST || 'http://localhost:4496'
  },
  datesFormat: 'DD/MM/YYYY HH:mm:ss',
  dayMonthFormat: 'DD/MM',
  dateOnlyFormat: 'DD/MM/YYYY',
  plugin: {
    pluginStyleUrl,
    pluginScriptUrl,
    blockId: 'outcore-chat-plugin'
  },
  foreignLinks: {
    dataUploadGuide:
      'https://docs.google.com/spreadsheets/d/1mbEyTrVFvHJ6NqOdHJYDu5D3OK278yJmDCBNYZzuAJE/edit?usp=sharing'
  },
  aws: {
    region: process.env.REACT_APP_AWS_REGION || 'eu-west-2',
    cognito: {
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || 'us-west-2_i2deOXkhu',
      userPoolClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID || '29t5p8g5vs1f6qjcjgscqrfqm4',
      userPoolEndpoint: process.env.REACT_APP_AWS_COGNITO_APP_URL || 'outcore-local.auth.eu-west-2.amazoncognito.com',
      redirectUrl: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URL || 'http://localhost:3000/login'
    }
  },
  ai: {
    urls: {
      [Pto.AI.ModelType.GPT3_5_Turbo]: 'https://api.openai.com/v1',
      [Pto.AI.ModelType.GPT4_Turbo]: 'https://api.openai.com/v1',
      [Pto.AI.ModelType.GPT4o]: 'https://api.openai.com/v1',
      [Pto.AI.ModelType.GPT4o_Mini]: 'https://api.openai.com/v1'
    },
    defaultWebSites: [
      'skillsforcareers.education.gov.uk',
      'whatuni.com',
      'opendays.com',
      'totaljobs.com',
      'thescholarshiphub.org.uk',
      'ucas.com',
      'careerpilot.org.uk',
      'exceptionalindividuals.com',
      'myworldofwork.co.uk',
      'futuregoals.co.uk',
      'icould.com',
      'targetcareers.co.uk',
      'healthcareers.nhs.uk',
      'nationalcareers.service.gov.uk',
      'amazingapprenticeships.com',
      'indeed.com',
      'apprenticeshipguide.co.uk',
      'gov.uk',
      'medschools.ac.uk',
      'apprenticeships.gov.uk',
      'prospects.ac.uk',
      'sacu-student.com',
      'theuniguide.co.uk',
      'u-explore.com',
      'thecompleteuniversityguide.co.uk'
    ]
  }
}

import { Pto } from '@outcoreaix/pto'
import { getQueryParamString } from 'utils/get-query-param-string'
import { apiSlice } from './api-splice'
import { RcFile } from 'antd/es/upload'

type GetWorkspaceMembersForOptionsQuery = {
  workspaceId: number
  searchText?: string
  excludeRoles?: Pto.Workspaces.WorkspaceRole
}

export const workspacesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspaces: builder.query<Pto.Workspaces.List, Pto.Workspaces.WorkspaceListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `workspaces?${queryParams}`,
          method: 'GET'
        }
      },
      serializeQueryArgs: ({ queryArgs }) => {
        const newQueryArgs = { ...queryArgs }
        if (newQueryArgs.page) {
          delete newQueryArgs.page
        }
        return newQueryArgs
      },
      merge: (currentCache, newItems, { arg }) => {
        if (!!arg.searchText || arg.page == 1) {
          return newItems
        }

        const existingIds = new Set(currentCache.items.map((item) => item.id))
        const mergedItems = [...currentCache.items, ...newItems.items.filter((item) => !existingIds.has(item.id))]

        const uniqueItems = Array.from(new Set(mergedItems.map((item) => item.id)))
          .map((id) => mergedItems.find((item) => item.id === id))
          .filter((item) => item !== undefined)
          .sort((a, b) => b!.id - a!.id) as unknown as Pto.Workspaces.WorkspaceListItem[]

        return {
          ...currentCache,
          items: uniqueItems
        }
      },
      providesTags: (result) => {
        return result
          ? [
              ...result.items.map(({ id }) => ({ type: 'Workspaces', id }) as const),
              { type: 'Workspaces' as const, id: 'LIST' }
            ]
          : [{ type: 'Workspaces' as const, id: 'LIST' }]
      }
    }),
    getWorkspacesOptions: builder.query<Pto.Workspaces.WorkspaceOption[], void>({
      query: () => ({
        url: 'workspaces/options',
        method: 'GET'
      }),
      providesTags: ['WorkspacesOptions']
    }),
    getWorkspace: builder.query<Pto.Workspaces.Workspace, number>({
      query: (id) => ({
        url: `workspaces/${id}`,
        method: 'GET'
      }),
      providesTags: ['Workspaces']
    }),
    addWorkspace: builder.mutation<Pto.Workspaces.Workspace, Pto.Workspaces.CreateWorkspace>({
      query: (data) => ({
        url: 'workspaces',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Workspaces', 'WorkspacesOptions']
    }),
    patchWorkspace: builder.mutation<Pto.Workspaces.Workspace, Pto.Workspaces.UpdateWorkspace & { id: number }>({
      query: ({ id, ...data }) => ({
        url: `workspaces/${id}`,
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Workspaces', id }]
    }),
    deleteWorkspace: builder.mutation<void, number>({
      query: (id) => ({
        url: `workspaces/${id}`,
        method: 'DELETE'
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          workspacesApi.util.updateQueryData('getWorkspaces', {}, (draft) => {
            draft.items = draft.items.filter((workspace) => workspace.id !== id)
          })
        )

        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, id) => [
        { type: 'Workspaces', id },
        { type: 'Workspaces', id: 'LIST' },
        { type: 'WorkspacesOptions' }
      ]
    }),
    getWorkspaceMembers: builder.query<
      Pto.Workspaces.ListMember,
      Pto.Workspaces.WorkspaceMembersListQuery & { id: number }
    >({
      query: ({ id, ...params }) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `workspaces/${id}/members/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: [{ type: 'Workspaces' }, 'Members']
    }),
    getWorkspaceMembersForOptions: builder.query<Pto.Meta[], GetWorkspaceMembersForOptionsQuery>({
      query: ({ workspaceId, searchText, excludeRoles }) => {
        const queryParams = getQueryParamString({ searchText, excludeRoles })
        return {
          url: `workspaces/${workspaceId}/members-for-options/?${queryParams}`,
          method: 'GET'
        }
      },
      providesTags: ['WorkspaceMembersOptions']
    }),
    patchWorkspaceKnowledgeBase: builder.mutation<void, { file: RcFile; id: number }>({
      query: ({ id, file }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)

        return {
          url: `workspaces/${id}/knowledge-base`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    }),
    patchWorkspaceLogo: builder.mutation<void, { file: RcFile; id: number; name?: string }>({
      query: ({ id, file, name }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)
        if (name) {
          body.append('workspaceName', name)
        }

        return {
          url: `workspaces/${id}/logo`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    }),
    patchWorkspaceOnboarding: builder.mutation<void, { file: RcFile; id: number; name?: string }>({
      query: ({ id, file, name }) => {
        const body = new FormData()
        body.append('Content-Type', file.type)
        body.append('file', file)
        if (name) {
          body.append('workspaceName', name)
        }

        return {
          url: `workspaces/${id}/onboarding`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: ['Workspaces']
    })
  })
})

export const {
  useGetWorkspacesQuery,
  useLazyGetWorkspacesQuery,
  useGetWorkspacesOptionsQuery,
  useGetWorkspaceQuery,
  useGetWorkspaceMembersForOptionsQuery,
  useLazyGetWorkspaceQuery,
  useAddWorkspaceMutation,
  usePatchWorkspaceMutation,
  useDeleteWorkspaceMutation,
  useGetWorkspaceMembersQuery,
  usePatchWorkspaceKnowledgeBaseMutation,
  usePatchWorkspaceLogoMutation,
  usePatchWorkspaceOnboardingMutation
} = workspacesApi

import React from 'react'
import querystringify from 'querystringify'
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { Pto } from '@outcoreaix/pto'

import { authStorage } from 'app/auth-storage'
import { useIsLoggedIn } from 'app/use-is-logged-in'
import { AppRoutes } from 'app/app-routes'

type Props = {
  roleRequired?: Pto.Users.UserRole[]
}

const PrivateRoute = ({ roleRequired = [] }: Props) => {
  const location = useLocation()
  const params = useParams()
  const isLoggedIn = useIsLoggedIn()
  const user = authStorage.user

  if (isLoggedIn && user) {
    let canAccess = !!user
    if (roleRequired.length && !user.roles.some((role) => role === Pto.Users.UserRole.SystemAdmin)) {
      canAccess = roleRequired.some((role) => user.roles.includes(role))
    }
    return canAccess ? <Outlet /> : <Navigate to="/" />
  }

  if (!location.pathname && !location.search && !params.inviteToken) {
    return <Navigate to={`${AppRoutes.login}`} />
  } else {
    const callbackUrl = querystringify.stringify({ callbackUrl: location.pathname + location.search })
    const inviteTokenPart = params.inviteToken ? `&inviteToken=${params.inviteToken}` : ''
    return <Navigate to={`${AppRoutes.login}?${callbackUrl}${inviteTokenPart}`} />
  }
}

export default PrivateRoute

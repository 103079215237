import { toast } from 'react-toastify'
import axios, { AxiosError } from 'axios'
import { settings } from 'config/settings'
import { authStorage } from './auth-storage'

const axiosInstance = axios.create({ baseURL: settings.backend.host, timeout: 60000 })

const handleError = (error: AxiosError) => {
  let errorMessage = ''

  if (Array.isArray((error.response?.data as any)?.message)) {
    errorMessage = (error.response?.data as any)?.message.join(', ')
  }

  if (typeof (error.response?.data as any)?.message === 'string' && !errorMessage) {
    errorMessage = (error.response?.data as any)?.message
  }

  if (error.message && !errorMessage) {
    errorMessage = error.message
  }

  toast.error(errorMessage || 'Unknown error')

  throw error
}

axiosInstance.interceptors.request.use(async (config) => {
  const token = authStorage.accessToken

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

axiosInstance.interceptors.response.use(null, async (error: AxiosError): Promise<any> => {
  if (error.response?.status === 401) {
    try {
      await authStorage.fetchSession(true)

      error.response.config.headers.authorization = authStorage.accessToken
      const res = await axiosInstance(error.response.config)
      return res
    } catch (exception: any) {
      if (exception.response?.status === 401) {
        authStorage.signOut()
      }
      return error
    }
  }

  handleError(error)
})

const customAxios = (token: string) => {
  const instance = axios.create({ baseURL: settings.backend.host, timeout: 60000 })

  instance.interceptors.request.use(async (config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  })

  instance.interceptors.response.use(null, handleError)

  return instance
}

export { customAxios }

export default axiosInstance

import { ExclamationCircleFilled } from '@ant-design/icons'
import { Button, ConfigProvider, Modal } from 'antd'
import { ComponentProps, FC, ReactNode } from 'react'

const { confirm } = Modal

type Props = {
  title: string
  buttonLabel: string
  buttonIcon: ReactNode
  disabled?: boolean
} & Omit<ComponentProps<typeof Modal>, 'title'> &
  ComponentProps<typeof Button>

const ConfirmModal: FC<Props> = ({
  title,
  buttonIcon,
  onOk,
  buttonLabel = 'Delete',
  okText = 'Yes',
  cancelText = 'No',
  disabled = false,
  ...rest
}) => {
  const showConfirm = () => {
    confirm({
      title,
      icon: <ExclamationCircleFilled />,
      okText,
      okType: 'danger',
      cancelText,
      onOk
    })
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            textHoverBg: 'rgba(0, 0, 0, 0)'
          }
        }
      }}
    >
      <Button
        onClick={(e) => {
          e.stopPropagation()
          showConfirm()
        }}
        icon={buttonIcon}
        style={{ width: '100%', textAlign: 'left' }}
        type="text"
        disabled={disabled}
        {...rest}
      >
        {buttonLabel}
      </Button>
    </ConfigProvider>
  )
}

export default ConfirmModal

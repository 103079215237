import { FileTextOutlined } from '@ant-design/icons'
import { FC } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  className?: string
  buttonText?: string
  title?: string
  subtitle?: string
  guideLink: string
}

const DataSchemaGuide: FC<Props> = ({
  className,
  buttonText = 'View Guide',
  title = 'Data Schema Guide',
  subtitle = 'View the Outcore Data Schema requirements and add your data accordingly',
  guideLink
}) => {
  return (
    <div className={`flex items-center p-4 bg-gray-50 rounded-lg shadow ${className}`}>
      <div className="flex items-center">
        <FileTextOutlined className="w-6 h-6 text-gray-500 mr-3 text-xl font-bold" />
        <div>
          <p className="font-semibold text-gray-900">{title}</p>
          <p className="text-sm text-gray-500">{subtitle}</p>
        </div>
      </div>
      <Link
        className="text-gray-600 font-semibold w-14 text-center mx-3 ml-auto"
        to={guideLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {buttonText}
      </Link>
    </div>
  )
}

export default DataSchemaGuide

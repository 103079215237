import { Pto } from '@outcoreaix/pto'
import { apiSlice } from './api-splice'
import { getQueryParamString } from 'utils/get-query-param-string'

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<Pto.Auth.JwtUser, void>({
      query: () => ({
        url: 'users/me',
        method: 'GET'
      })
    }),
    updateUser: builder.mutation<Pto.Auth.JwtUser, Pto.Users.UpdateUser>({
      query: (data) => ({
        url: 'users',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['Users']
    }),
    sendVerificationCode: builder.mutation<void, Pto.Users.SendVerificationCode>({
      query: (body) => ({
        url: 'users/send-verification-code',
        method: 'POST',
        body
      })
    }),
    phoneVerification: builder.mutation<Pto.Auth.JwtUser, Pto.Users.PhoneVerification>({
      query: (body) => ({
        url: 'users/phone-verification',
        method: 'POST',
        body
      })
    }),
    resendInvitation: builder.mutation<void, Pto.Users.ResendInvite>({
      query: (body) => ({
        url: 'users/resend-invitation',
        method: 'POST',
        body
      })
    }),
    incidentReport: builder.mutation<void, Pto.Users.IncidentReport>({
      query: (body) => ({
        url: 'users/incident-report',
        method: 'POST',
        body
      })
    }),
    submitFeedback: builder.mutation<void, Pto.Users.SendFeedback>({
      query: (body) => ({
        url: 'users/feedbacks',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Feedback']
    }),
    getFeedback: builder.query<Pto.Users.Feedback, void>({
      query: () => ({
        url: 'users/feedback',
        method: 'GET'
      }),
      providesTags: ['Feedback']
    }),
    getFeedbacks: builder.query<Pto.Users.FeedbackList, Pto.Users.FeedbackListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `users/feedbacks?${queryParams}`,
          method: 'GET'
        }
      }
    }),
    getFeedbacksHistory: builder.query<Pto.Users.FeedbackHistoryList, Pto.Users.FeedbackHistoryListQuery>({
      query: (params) => {
        const queryParams = getQueryParamString(params)
        return {
          url: `users/feedbacks-history?${queryParams}`,
          method: 'GET'
        }
      }
    }),
    deleteFeedback: builder.mutation<Pto.Users.Feedback, void>({
      query: () => ({
        url: 'users/feedbacks',
        method: 'DELETE'
      }),
      invalidatesTags: ['Feedback']
    })
  })
})

export const {
  useMeQuery,
  useIncidentReportMutation,
  useResendInvitationMutation,
  useUpdateUserMutation,
  useSendVerificationCodeMutation,
  usePhoneVerificationMutation,
  useSubmitFeedbackMutation,
  useGetFeedbackQuery,
  useGetFeedbacksQuery,
  useGetFeedbacksHistoryQuery,
  useDeleteFeedbackMutation
} = usersApi

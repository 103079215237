import { MoreOutlined } from '@ant-design/icons'
import { Button, ConfigProvider, Dropdown, MenuProps } from 'antd'
import { FC } from 'react'

type Props = {
  items: MenuProps['items']
}
const CardMenuDropdown: FC<Props> = ({ items }) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Dropdown: {
            controlPaddingHorizontal: 0,
            paddingBlock: 0
          }
        }
      }}
    >
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button type="text" size="small" icon={<MoreOutlined />} onClick={(e) => e.preventDefault()} />
      </Dropdown>
    </ConfigProvider>
  )
}

export default CardMenuDropdown

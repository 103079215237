import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Flex, Typography } from 'antd'
import clsx from 'clsx'
import { FC, PropsWithChildren, ReactNode } from 'react'

type Props = {
  title: string | ReactNode
  text?: string | ReactNode
  onBack?: () => void
  allowSkip?: boolean
  okLabel?: string
  onSkip?: () => void
  form: string
  notes?: string | ReactNode
  onOk?: () => void
  isLoading?: boolean
  skipLabel?: string
  disabled?: boolean
  className?: string
} & PropsWithChildren

const StepTemplate: FC<Props> = ({
  children,
  title,
  text,
  allowSkip = false,
  okLabel = 'Continue',
  skipLabel = 'Skip',
  onBack,
  form,
  onSkip,
  onOk,
  isLoading = false,
  notes,
  disabled = false,
  className
}) => {
  return (
    <Flex vertical className={clsx('mx-0 mb-6 mt-2 gap-6 md:gap-8', className)}>
      <Flex vertical gap={8}>
        {title && (
          <Typography.Title className="m-0 !mb-0 whitespace-pre-line" level={2}>
            {title}
          </Typography.Title>
        )}
        {text && <Typography.Text>{text}</Typography.Text>}
      </Flex>
      <Flex vertical gap={16} className="children-wrapper">
        {children}
        <Flex vertical>{notes}</Flex>
      </Flex>
      <Flex justify="space-between" align="center">
        <Flex className="!flex">
          {!!onBack && (
            <Button
              type="text"
              size="large"
              disabled={isLoading || disabled}
              icon={<ArrowLeftOutlined />}
              onClick={onBack}
            >
              Back
            </Button>
          )}
        </Flex>
        <Flex gap={8}>
          {allowSkip && (
            <Button type="text" size="large" disabled={isLoading || disabled} onClick={onSkip}>
              {skipLabel}
            </Button>
          )}
          <Button
            loading={isLoading}
            htmlType="submit"
            form={form}
            size="large"
            type="primary"
            disabled={disabled}
            onClick={() => onOk?.()}
          >
            {okLabel}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StepTemplate

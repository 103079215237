import { Flex, Spin } from 'antd'
import { FC, PropsWithChildren } from 'react'

type Props = {
  isLoading: boolean
} & PropsWithChildren

const ComponentWithSpinner: FC<Props> = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <Flex className="w-full h-full" justify="center" align="center">
        <Spin size="large" spinning />
      </Flex>
    )
  }

  return <>{children}</>
}

export default ComponentWithSpinner

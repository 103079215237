import React from 'react'
import querystringify from 'querystringify'
import { Navigate, useLocation } from 'react-router-dom'

import { useIsLoggedIn } from 'app/use-is-logged-in'

const AnonymousRoute = ({ children }: React.PropsWithChildren) => {
  const location = useLocation()

  const isLoggedIn = useIsLoggedIn()

  if (isLoggedIn) {
    const { callbackUrl } = querystringify.parse(location.search) as any

    if (callbackUrl) {
      return <Navigate to={callbackUrl} />
    } else {
      return <Navigate to="/" />
    }
  }

  return <>{children}</>
}

export default AnonymousRoute

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'
import { NavMenu } from 'components/Navigation'
import { WorkspacesMenuDrawer } from 'components/WorkspacesMenuDrawer'
import Feedback from 'features/users/components/Feedback'
import { useDisclosure } from 'hooks/useDisclosure'
import { FC } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

type Props = {
  hasSubMenu?: boolean
}
const AppLayout: FC<Props> = ({ hasSubMenu = false }) => {
  const { open, close, isOpen } = useDisclosure()
  return (
    <div className="relative z-0 flex-col flex h-full w-full overflow-hidden xl:flex-row ">
      {hasSubMenu && <Feedback />}
      <NavMenu open={isOpen} onClose={close} hasSubMenu={hasSubMenu} />
      <div className="relative flex h-full max-w-full flex-1 flex-col overflow-hidden">
        <div className="sticky top-0 z-10 flex min-h-14 bg-background items-center justify-start px-4 md:px-8 xl:hidden w-full">
          <Button type="text" onClick={open} className="xl:hidden items-center flex h-10 -ml-3 mr-3">
            {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
          <WorkspacesMenuDrawer />
        </div>
        <Divider className="xl:hidden m-0" />
        <div className="relative h-full w-full flex-1 overflow-auto transition-width">
          <Outlet context={useOutletContext()} />
        </div>
      </div>
    </div>
  )
}

export default AppLayout

import type { FunctionComponent, JSXElementConstructor, ReactElement } from 'react'

// Factor for the conditional rendering using ternary or logical operators
type Children = ReactElement<any, string | JSXElementConstructor<any>> | undefined | null

export interface ConditionalWrapperProps<T> {
  condition: boolean
  Wrapper: FunctionComponent<T>
  wrapperProps?: T
  children: Children | Children[]
}

function ConditionalWrapper<T>({ condition, Wrapper, wrapperProps = {} as T, children }: ConditionalWrapperProps<T>) {
  return condition ? <Wrapper {...wrapperProps}>{children}</Wrapper> : <>{children}</>
}

export default ConditionalWrapper

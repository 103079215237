import { useGetWorkspacesOptionsQuery } from 'api/workspaces-api'
import { useParams } from 'react-router-dom'

export const useWorkspace = () => {
  const param = useParams()
  const { data, isLoading } = useGetWorkspacesOptionsQuery()
  const currentWorkspace = param.workspaceId ? data?.find((item) => item.id === Number(param.workspaceId)) : undefined

  return {
    currentWorkspace,
    currentWorkspaceId: param.workspaceId ? Number(param.workspaceId) : undefined,
    workspaces: data,
    isLoading
  }
}

import { Divider, Drawer, Flex, Layout } from 'antd'
import { useIsLoggedIn } from 'app/use-is-logged-in'
import { FC, useMemo } from 'react'
import UserMenuItem from '../UserMenuItem'
import MenuList from '../MenuList'
import { useLocation } from 'react-router-dom'
import { useWorkspace } from 'hooks/useWorkspace'
import SettingsMenu from '../SettingsMenu'

type Props = {
  open: boolean
  onClose: () => void
  hasSubMenu?: boolean
}

const NavMenu: FC<Props> = ({ open, onClose, hasSubMenu = false }) => {
  const isLoggedIn = useIsLoggedIn()
  const { currentWorkspace } = useWorkspace()
  const location = useLocation()

  const isSettingsPage = useMemo(
    () => location.pathname.includes('settings') && !location.pathname.includes('app-settings'),
    [location.pathname]
  )

  if (!isLoggedIn) {
    return null
  }

  return (
    <>
      <Layout.Sider
        key={`${location.pathname}_desktop`}
        breakpoint="xl"
        trigger={null}
        collapsedWidth={isSettingsPage ? 380 : 80}
        theme="light"
        collapsed={hasSubMenu && !isSettingsPage}
        width={isSettingsPage ? 380 : 300}
        className="max-xl:hidden"
      >
        <Flex vertical justify="space-between" className="h-full">
          <Layout>
            <Layout.Sider
              breakpoint="xl"
              theme="light"
              trigger={null}
              collapsedWidth="0"
              width={300}
              className="py-6 px-4 max-xl:hidden h-dvh xl:h-screen border-r-menu border-r [&_.ant-layout-sider-children]:flex [&_.ant-layout-sider-children]:flex-col [&_.ant-layout-sider-children]:justify-between"
              {...(hasSubMenu && { collapsed: true, collapsedWidth: '80' })}
            >
              <MenuList isDesktop collapsed={hasSubMenu} />
              <UserMenuItem collapsed={hasSubMenu} workspace={currentWorkspace} />
            </Layout.Sider>
            {isSettingsPage && (
              <>
                <SettingsMenu />
                <Divider type="vertical" className="mx-0 h-full !border-menu" />
              </>
            )}
          </Layout>
        </Flex>
      </Layout.Sider>
      <Drawer
        key={`${location.pathname}_drawer`}
        classNames={{ header: '[&>div>button]:!-ml-1 !py-4', body: '!pb-0' }}
        title={
          <div className="text-ellipsis overflow-hidden whitespace-nowrap max-w-80">{currentWorkspace?.name || ''}</div>
        }
        onClose={onClose}
        open={open}
        placement="left"
        className="xl:hidden"
      >
        <Flex vertical justify="space-between" className="h-full">
          <MenuList key={`${location.pathname}_mobile`} onClick={onClose} isDesktop={false} />
          <UserMenuItem onMenuItemClick={onClose} />
        </Flex>
      </Drawer>
    </>
  )
}

export default NavMenu

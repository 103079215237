import { ConfigProvider } from 'antd'
import { FC, PropsWithChildren } from 'react'

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0582ef',
          colorInfo: '#0582ef',
          colorSuccess: '#12b76a',
          colorWarning: '#f79009',
          colorError: '#f04438',
          colorTextBase: '#101828',
          borderRadius: 8,
          fontSize: 16
        },
        components: {
          Layout: {
            bodyBg: '#F9FAFB',
            headerBg: '#F9FAFB'
          },
          Table: {
            headerBg: '#EBF6FF',
            borderColor: '#EAECF0',
            fontWeightStrong: 500,
            boxShadowSecondary: 'none',
            cellFontSize: 14
          },
          Form: {
            labelFontSize: 14,
            fontSize: 14,
            lineHeight: 1,
            itemMarginBottom: 18,
            controlHeightSM: 18
          },
          Button: {
            fontWeight: 600,
            paddingInline: 11,
            paddingBlockLG: 7,
            controlHeightLG: 42
          },
          Drawer: {
            paddingLG: 16
          },
          Menu: {
            subMenuItemBg: '#ffffff'
          },
          Slider: {
            handleSize: 11,
            dotSize: 14
          },
          Input: {
            paddingInline: 14,
            paddingBlock: 8,
            controlHeight: 42
          },
          Select: {
            controlHeightLG: 42
          }
        }
      }}
    >
      {children}
    </ConfigProvider>
  )
}

export default ThemeProvider

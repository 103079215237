import Markdown from 'react-markdown'
import { FC, useMemo } from 'react'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import Prism from 'prismjs'

type Props = {
  content: string
}

const MarkdownMessage: FC<Props> = ({ content }) => {
  const memoizedContent = useMemo(() => {
    return content
  }, [content])

  return (
    <Markdown
      skipHtml={false}
      className="markdown-custom"
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        a: ({ node, ...props }) => (
          <a {...props} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        ),
        code({ node, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return match ? (
            <pre className={className}>
              <code
                className={`language-${match[1]}`}
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(String(children), Prism.languages['javascript'], 'javascript')
                }}
              />
            </pre>
          ) : null
        }
      }}
    >
      {memoizedContent}
    </Markdown>
  )
}

export default MarkdownMessage

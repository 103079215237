import React, { useState } from 'react'
import { Button, Input } from 'antd'

type Props = {
  disabled?: boolean
  sourceValue?: number | undefined
  onChange?: (newValue: number) => void | Promise<void>
  className?: string
}

const PhoneInput = ({ disabled = false, sourceValue, onChange, className = '' }: Props) => {
  const [value, setValue] = useState<string | undefined>(sourceValue?.toString())

  const onChangeHandle = (newValue: string) => {
    const sanitizedValue = newValue.replace(/\D/g, '')
    if (!sanitizedValue) {
      setValue('')
      return
    }

    const finalValue = Number(sanitizedValue.length <= 15 ? sanitizedValue : sanitizedValue.slice(0, 15))

    onChange && onChange(finalValue)
    setValue(finalValue.toString())
  }

  return (
    <Input
      disabled={disabled}
      className={className}
      value={value}
      onInput={(event) => onChangeHandle((event.target as HTMLInputElement).value)}
      min={1}
      size="large"
      prefix="+"
      style={{ width: '100%' }}
    />
  )
}

export default PhoneInput

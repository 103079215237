import { useEffect, useState } from 'react'
import { authStorage } from './auth-storage'

const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!authStorage.user)

  useEffect(() => {
    const handleUserSignIn = () => {
      setIsLoggedIn(true)
    }

    const handleUserSignOut = () => {
      setIsLoggedIn(false)
    }

    authStorage.addEventListener('user-signed-in', handleUserSignIn)
    authStorage.addEventListener('user-signed-out', handleUserSignOut)

    return () => {
      authStorage.removeEventListener('user-signed-in', handleUserSignIn)
      authStorage.removeEventListener('user-signed-out', handleUserSignOut)
    }
  }, [])

  return isLoggedIn
}

export { useIsLoggedIn }

import { Pto } from '@outcoreaix/pto'
import { Button, Flex, Spin, Typography } from 'antd'
import { useCheckMembershipQuery, useGetSpacesQuery } from 'api/spaces-api'
import { AppRoutes } from 'app/app-routes'
import { authStorage } from 'app/auth-storage'
import { useEffect } from 'react'
import { Link, Navigate, Outlet, useLocation, useOutletContext, useParams } from 'react-router-dom'

type Props = {
  roleRequired?: (Pto.Users.UserRole | Pto.Workspaces.WorkspaceRole)[]
}

const UserAccessRoute = ({ roleRequired = [] }: Props) => {
  const { roleMap } = useOutletContext<{
    roleMap: Record<number, Pto.Users.UserRole | Pto.Workspaces.WorkspaceRole> | null
  }>()

  const params = useParams()
  const location = useLocation()
  const user = authStorage.user
  const currentWorkspace = params.workspaceId
  const currentWorkspaceRole = currentWorkspace ? roleMap?.[Number(currentWorkspace)] : undefined

  const { data: memberData, isLoading } = useCheckMembershipQuery(
    { workspaceId: Number(currentWorkspace) },
    {
      skip: currentWorkspace === undefined || currentWorkspaceRole !== Pto.Workspaces.WorkspaceRole.Contact
    }
  )

  const {
    data: spacesData,
    isLoading: spacesLoading,
    isUninitialized,
    refetch
  } = useGetSpacesQuery(
    {
      workspaceId: Number(currentWorkspace)
    },
    { skip: currentWorkspace === undefined }
  )

  useEffect(() => {
    if (!isUninitialized && currentWorkspace !== undefined) {
      refetch()
    }
  }, [user, refetch, isUninitialized])

  if (
    (!currentWorkspaceRole && currentWorkspace) ||
    (!currentWorkspaceRole &&
      roleRequired.includes(Pto.Users.UserRole.SystemAdmin) &&
      !user?.roles.includes(Pto.Users.UserRole.SystemAdmin))
  ) {
    return <Navigate to={AppRoutes.main} />
  }

  if (currentWorkspaceRole && !roleRequired.includes(currentWorkspaceRole)) {
    return (
      <Flex vertical className="w-full h-dvh lg:h-screen" justify="center" align="center" gap={16}>
        <Flex>
          <Typography.Title level={2}>Access denied!</Typography.Title>
        </Flex>
        <Link to={AppRoutes.main}>
          <Button
            type="primary"
            size="large"
            onClick={() => localStorage.removeItem(`${authStorage.user?.id}-workspace`)}
          >
            Go to the main page
          </Button>
        </Link>
      </Flex>
    )
  }

  if (isLoading) {
    return (
      <Flex justify="center" align="center" className="w-full h-dvh lg:h-screen">
        <Spin spinning size="large" />
      </Flex>
    )
  }

  localStorage.setItem(`${user?.id}-workspace`, `${currentWorkspace}`)

  if (
    memberData?.questions?.filter((q) => q.type === Pto.Spaces.QuestionType.Regular).length &&
    memberData.questions?.filter((q) => q.type === Pto.Spaces.QuestionType.Regular).length !==
      memberData.memberAnswers?.length &&
    location.pathname !== `/workspaces/${memberData.workspaceId}/space/${memberData.id}/onboarding`
  ) {
    return <Navigate to={`/workspaces/${memberData.workspaceId}/space/${memberData.id}/onboarding`} />
  }

  return (
    <Outlet
      context={{ isLoading: spacesLoading, spaces: spacesData?.items || [], currentWorkspace, currentWorkspaceRole }}
    />
  )
}

export default UserAccessRoute

import { FileTextOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { FC } from 'react'

type Props = {
  className?: string
  buttonText?: string
  title?: string
  subtitle?: string
  downloadButtonType: 'default' | 'link'
  downloadFunction: () => void
}

const DownloadTemplateButton: FC<Props> = ({
  className,
  buttonText = 'Download Template',
  title = 'Need a template?',
  subtitle = 'Use our pre-formatted CSV template for quick imports',
  downloadButtonType,
  downloadFunction
}) => {
  return (
    <div className={`flex items-center p-4 bg-blue-50 rounded-lg shadow ${className}`}>
      <div className="flex items-center">
        <FileTextOutlined className="w-6 h-6 text-blue-500 mr-3 text-xl font-bold" />
        <div className="min-w-36">
          <p className="font-semibold text-gray-900">{title}</p>
          <p className="text-sm text-gray-500">{subtitle}</p>
        </div>
      </div>
      <Button
        className="ml-auto py-2 px-4 rounded-md border-background min-h-10 text-blue-600 whitespace-normal"
        onClick={downloadFunction}
        type={downloadButtonType}
      >
        {buttonText}
      </Button>
    </div>
  )
}

export default DownloadTemplateButton

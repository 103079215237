import { fetchBaseQuery, BaseQueryFn, BaseQueryApi, FetchArgs } from '@reduxjs/toolkit/query/react'
import { settings } from 'config/settings'
import { authStorage } from './auth-storage'

const { host } = settings.backend

export const prepareHeaders = async () => {
  const headers = new Headers()

  if (authStorage.accessToken || authStorage.getTemporaryToken()) {
    headers.set('Authorization', `Bearer ${authStorage.accessToken || authStorage.getTemporaryToken()}`)
    return headers
  }

  return headers
}

const baseQuery = fetchBaseQuery({
  baseUrl: host,
  prepareHeaders
})

export const baseQueryWithReauth: BaseQueryFn = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
): Promise<any> => {
  let result = await baseQuery(args, api, extraOptions)

  if (result?.error && result?.error?.status === 401) {
    await authStorage.fetchSession(true)

    if (!authStorage.user) {
      authStorage.signOut()
      return
    }

    result = await baseQuery(args, api, extraOptions)
  }

  return result
}

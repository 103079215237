import { Pto } from '@outcoreaix/pto'
import { Avatar, Button, Card, Drawer, List, Skeleton } from 'antd'
import { AppRoutes } from 'app/app-routes'
import { authStorage } from 'app/auth-storage'
import { useDisclosure } from 'hooks/useDisclosure'
import { useWorkspace } from 'hooks/useWorkspace'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const WorkspacesMenuDrawer: FC = () => {
  const { open, close, isOpen } = useDisclosure()
  const { currentWorkspace, workspaces, isLoading } = useWorkspace()
  const user = authStorage.user
  const handleItemClick = (workspace: Pto.Workspaces.WorkspaceOption) => {
    localStorage.setItem(`${user?.id}-workspace`, workspace.id.toString())
    close()
  }

  if (!workspaces?.length || user?.roles.includes(Pto.Users.UserRole.SystemAdmin)) {
    return null
  }
  return (
    <>
      <Button
        type="text"
        size="large"
        onClick={open}
        className="xl:hidden items-center flex max-w-[85vw] [&>span]:text-ellipsis [&>span]:overflow-hidden [&>span]:whitespace-nowrap h-12 -ml-[15px]"
      >
        {currentWorkspace?.name}
      </Button>
      <Drawer
        title="Workspaces"
        classNames={{ header: '[&>div>button]:!-ml-1 !py-4' }}
        onClose={close}
        open={isOpen}
        placement="left"
        className="xl:hidden"
      >
        <List
          className="w-full"
          itemLayout="horizontal"
          dataSource={workspaces || []}
          loading={isLoading}
          renderItem={(item) => (
            <List.Item className="w-full">
              <Skeleton active loading={isLoading}>
                <Link
                  className="w-full"
                  to={`${AppRoutes.workspaces}/${item.id}/space`}
                  onClick={() => handleItemClick(item)}
                >
                  <Card hoverable className="w-full">
                    <Card.Meta
                      className="items-center"
                      avatar={<Avatar size={40} {...(item.logoUrl && { src: item.logoUrl })} />}
                      title={item.name}
                    />
                  </Card>
                </Link>
              </Skeleton>
            </List.Item>
          )}
        />
      </Drawer>
    </>
  )
}

export default WorkspacesMenuDrawer

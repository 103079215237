import Icon, {
  AppstoreOutlined,
  BarChartOutlined,
  PlusOutlined,
  SettingOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  StopOutlined,
  CommentOutlined,
  CustomerServiceOutlined,
  RocketOutlined
} from '@ant-design/icons'
import { Pto } from '@outcoreaix/pto'
import { AppRoutes } from 'app/app-routes'
import { Link } from 'react-router-dom'

export const generateSystemAdminMenuItems = (onClick?: () => void) => {
  return [
    // {
    //   key: AppRoutes.dashboard,
    //   icon: <BarChartOutlined />,
    //   label: 'Dashboard',
    //   disabled: true,
    //   style: { paddingLeft: '12px' }
    // },
    {
      key: AppRoutes.workspaces,
      icon: <AppstoreOutlined />,
      label: (
        <Link onClick={onClick} to={AppRoutes.workspaces}>
          Workspaces
        </Link>
      ),
      style: { paddingLeft: '12px' }
    },
    {
      key: AppRoutes.feedbacks,
      icon: <CommentOutlined />,
      label: (
        <Link onClick={onClick} to={AppRoutes.feedbacks}>
          Feedbacks
        </Link>
      ),
      style: { paddingLeft: '12px' }
    },
    {
      key: AppRoutes.appSettings,
      icon: <SettingOutlined />,
      label: (
        <Link onClick={onClick} to={AppRoutes.appSettings}>
          App Settings
        </Link>
      ),
      style: { paddingLeft: '12px' }
    },
    {
      key: AppRoutes.conversationStartersManagement,
      icon: <RocketOutlined />,
      label: (
        <Link
          onClick={onClick}
          to={AppRoutes.conversationStartersManagement}
          style={{
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            display: 'block',
            lineHeight: '1.2'
          }}
        >
          Conversation Starter Management
        </Link>
      ),
      style: {
        paddingLeft: '12px'
      }
    }
  ]
}

export const generateUserMenuItems = (
  workspace?: Pto.Workspaces.WorkspaceOption,
  onClick?: () => void,
  spaces?: Pto.Spaces.SpaceListItem[],
  onSubItemClick?: (value: string, type: 'space' | 'workspace') => void,
  isDesktop?: boolean
) => [
  ...(workspace?.currentMember?.role !== Pto.Workspaces.WorkspaceRole.Contact
    ? [
        {
          key: `${AppRoutes.workspaces}/${workspace?.id}/dashboard`,
          icon: <BarChartOutlined />,
          label: (
            <Link onClick={onClick} to={`${AppRoutes.workspaces}/${workspace?.id}/dashboard`}>
              Dashboard
            </Link>
          ),
          style: { paddingLeft: '12px' }
        },
        workspace?.currentMember?.tags.includes(Pto.Workspaces.WorkspaceMemberTag.Safeguarding)
          ? {
              key: `${AppRoutes.workspaces}/${workspace?.id}${AppRoutes.alerts}`,
              icon: <ExclamationCircleOutlined />,
              label: (
                <Link onClick={onClick} to={`${AppRoutes.workspaces}/${workspace?.id}${AppRoutes.alerts}`}>
                  Alerts
                </Link>
              ),
              style: { paddingLeft: '12px' }
            }
          : null
      ]
    : []),
  {
    key: `spaces_chat`,
    icon: (
      <Icon
        component={() => (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor">
            <path d="m11 1 .447-.894a1 1 0 0 0-.894 0L11 1ZM1 6l-.447-.894a1 1 0 0 0 0 1.788L1 6Zm10 5-.447.894a1 1 0 0 0 .894 0L11 11Zm10-5 .447.894a1 1 0 0 0 0-1.788L21 6ZM1.447 15.106a1 1 0 1 0-.894 1.788l.894-1.788ZM11 21l-.447.894a1 1 0 0 0 .894 0L11 21Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788Zm-20-6.788a1 1 0 1 0-.894 1.788l.894-1.788ZM11 16l-.447.894a1 1 0 0 0 .894 0L11 16Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788ZM10.553.106l-10 5 .894 1.788 10-5-.894-1.788Zm-10 6.788 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm10-6.788-10-5-.894 1.788 10 5 .894-1.788ZM.553 16.894l10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm-10.894-10 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Z" />
          </svg>
        )}
      />
    ),
    label: 'Spaces',
    className: 'xl:!pl-0 [&_.ant-menu-submenu-title]:!pl-3',
    disabled: !spaces?.length,
    children: spaces?.map((space) => ({
      key: `${AppRoutes.workspaces}/${workspace?.id}/space/${space.id}`,
      disabled: space.status === Pto.Spaces.SpaceStatus.Inactive,
      style: { paddingLeft: '36px' },
      label: (
        <Link
          onClick={onClick}
          to={`${AppRoutes.workspaces}/${workspace?.id}/space/${space.id}`}
          className={`${space.status === Pto.Spaces.SpaceStatus.Inactive ? 'pointer-events-none click:bg-transparent' : ''}`}
        >
          {space.status === Pto.Spaces.SpaceStatus.Inactive && <StopOutlined className="text-red-500 mr-1" />}
          {space.name}
        </Link>
      )
    }))
  },

  ...(!isDesktop && workspace?.currentMember?.role !== Pto.Workspaces.WorkspaceRole.Contact
    ? [
        {
          label: 'Settings',
          key: `settings/${workspace?.id}`,
          icon: <SettingOutlined />,
          className: 'xl:!pl-3 !pl-0 xl:[&_.ant-menu-submenu-title]:!pl-0 [&_.ant-menu-submenu-title]:!pl-3',
          children: [
            {
              key: `${AppRoutes.settings}/${workspace?.id}/profile-settings`,
              icon: <UserOutlined />,
              label: (
                <Link onClick={onClick} to={`${AppRoutes.settings}/${workspace?.id}/profile-settings`}>
                  Profile settings
                </Link>
              ),
              style: { paddingLeft: '28px' }
            },
            {
              key: `${AppRoutes.settings}/${workspace?.id}/support`,
              icon: <CustomerServiceOutlined />,
              label: (
                <Link onClick={onClick} to={`${AppRoutes.settings}/${workspace?.id}/support`}>
                  Support
                </Link>
              ),
              style: { paddingLeft: '28px' }
            },
            {
              type: 'group',
              label: (
                <div className="flex gap-2 items-center">
                  <AppstoreOutlined className="w-4 h-4" />
                  Workspace
                </div>
              ),
              children: [
                {
                  key: workspace?.name,
                  label: (
                    <div className="pl-1" onClick={() => onSubItemClick?.(workspace!.id.toString(), 'workspace')}>
                      {workspace?.name}
                    </div>
                  )
                }
              ]
            },
            {
              type: 'group',
              label: (
                <div className="flex gap-2 items-center">
                  <Icon
                    className="w-4 h-4"
                    component={() => (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="currentColor">
                        <path d="m11 1 .447-.894a1 1 0 0 0-.894 0L11 1ZM1 6l-.447-.894a1 1 0 0 0 0 1.788L1 6Zm10 5-.447.894a1 1 0 0 0 .894 0L11 11Zm10-5 .447.894a1 1 0 0 0 0-1.788L21 6ZM1.447 15.106a1 1 0 1 0-.894 1.788l.894-1.788ZM11 21l-.447.894a1 1 0 0 0 .894 0L11 21Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788Zm-20-6.788a1 1 0 1 0-.894 1.788l.894-1.788ZM11 16l-.447.894a1 1 0 0 0 .894 0L11 16Zm10.447-4.106a1 1 0 1 0-.894-1.788l.894 1.788ZM10.553.106l-10 5 .894 1.788 10-5-.894-1.788Zm-10 6.788 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm10-6.788-10-5-.894 1.788 10 5 .894-1.788ZM.553 16.894l10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Zm-10.894-10 10 5 .894-1.788-10-5-.894 1.788Zm10.894 5 10-5-.894-1.788-10 5 .894 1.788Z" />
                      </svg>
                    )}
                  />
                  Spaces
                </div>
              ),
              children: spaces?.map((space) => ({
                key: `space_${space?.id}`,
                label: (
                  <div className={`pl-1`} onClick={() => onSubItemClick?.(space.id.toString(), 'space')}>
                    {space.status === Pto.Spaces.SpaceStatus.Inactive && <StopOutlined className="text-red-500 mr-1" />}
                    {space.name}
                  </div>
                )
              }))
            },
            {
              key: `space_create`,
              label: (
                <Link to={`${AppRoutes.workspaces}/${workspace?.id}/space/set-up`}>
                  <button className="justify-center items-center [&>span]:!m-0 pl-1 flex gap-2 font-medium">
                    <PlusOutlined />
                    <div>Create space</div>
                  </button>
                </Link>
              )
            }
          ]
        }
      ]
    : [])
]

export const generateSpaceSettingsMenu = (workspaceId?: number, spaceId?: number, onClick?: () => void) => [
  {
    key: `/settings/${workspaceId}/space/${spaceId}/general`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/space/${spaceId}/general`}>
        General
      </Link>
    )
  },
  {
    key: `/settings/${workspaceId}/space/${spaceId}/ai-config`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/space/${spaceId}/ai-config`}>
        AI Configuration
      </Link>
    )
  },
  {
    key: `/settings/${workspaceId}/space/${spaceId}/onboarding-interview`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/space/${spaceId}/onboarding-interview`}>
        Induction Questions
      </Link>
    )
  },
  {
    key: `/settings/${workspaceId}/space/${spaceId}/members`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/space/${spaceId}/members`}>
        Members
      </Link>
    )
  }
]

export const generateWorkspaceSettingsMenu = (workspaceId?: number, onClick?: () => void) => [
  {
    key: `/settings/${workspaceId}/general`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/general`}>
        General
      </Link>
    )
  },
  {
    key: `/settings/${workspaceId}/members`,
    label: (
      <Link onClick={onClick} to={`/settings/${workspaceId}/members`}>
        Members
      </Link>
    )
  }
]
